<template>
  <div id="help">
  </div>
</template>

<script>
import { getStorage } from '@/lib/util'
export default {
    data () {
    return {
      jumpUrl:'',
      ossurl: ''
    }
  },
  components: {

  },
  created () {
    this.ossurl = getStorage('ossurl', '')
    this.jumpUrl = this.$route.query.jumppath
    document.title = this.$route.query.title
  },
  mounted () {
    this.jump() 
  },
  methods: {
    jump(){
      $('#help').load('https://saasms-test.oss-cn-beijing.aliyuncs.com/' + this.jumpUrl)
      // console.log(this.ossurl + this.jumpUrl)
    }
  }
}
</script>